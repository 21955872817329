<template>
    <icon-base :width="size + 'px'" :fill="color">
    <g>
        <path class="st7" d="M0.695,11.908c3.753,0,7.506,0.027,11.259-0.02c0.859-0.011,1.108,0.207,1.103,1.088   c-0.038,6.539-0.021,13.077-0.021,19.616c0,0.416,0,0.832,0,1.436c1.904-1.102,3.762-1.893,5.744-2.394   c3.143-0.795,6.352-0.851,9.555-0.869c6.112-0.034,12.226-0.081,18.335,0.061c6.565,0.153,12.584,2.019,17.518,6.557   c4.958,4.56,7.436,10.332,7.502,17.01c0.114,11.645,0.022,23.292,0.052,34.938c0.002,0.884-0.249,1.1-1.107,1.088   c-3.404-0.049-6.81-0.056-10.214,0.003c-0.943,0.016-1.15-0.28-1.144-1.173c0.069-9.747,0.104-19.495,0.131-29.242   c0.006-2.393,0.077-4.802-0.404-7.161c-1.144-5.601-5.405-9.333-11.083-9.652c-7.496-0.421-15.004-0.39-22.5-0.101   c-7.466,0.288-12.408,5.637-12.415,13.136c-0.011,10.988-0.015,21.975,0.018,32.963c0.003,0.935-0.185,1.25-1.186,1.233   c-3.713-0.064-7.428-0.026-11.143-0.026C0.695,64.235,0.695,38.072,0.695,11.908z"/>
        <path class="st7" d="M212.42,90.398c-7.158,0-14.317,0-21.475,0c-12.471,0-21.442-6.902-24.046-19.079   c-1.756-8.212-1.762-16.561,0.951-24.624c3.067-9.113,9.671-14.139,19.125-15.551c2.077-0.31,4.171-0.311,6.246-0.313   c14.391-0.012,28.782,0.078,43.173,0.093c0.997,0.001,1.204,0.295,1.187,1.233c-0.058,3.288-0.058,6.578,0,9.866   c0.017,0.951-0.231,1.227-1.207,1.224c-14.432-0.048-28.864-0.055-43.296-0.071c-0.464-0.001-0.93,0.033-1.393,0.004   c-6.889-0.431-12.102,5.696-13.028,11.44c-0.729,4.52-0.707,9.122,0.095,13.631c1.01,5.682,5.443,9.48,11.207,9.825   c8.502,0.509,17.017,0.262,25.524,0.169c7.077-0.077,14.155-0.039,21.231-0.173c0.79-0.015,0.86,0.325,0.856,0.963   c-0.023,3.444-0.048,6.888,0.014,10.331c0.017,0.939-0.33,1.05-1.134,1.047C228.439,90.388,220.43,90.398,212.42,90.398z"/>
        <path class="st7" d="M119.826,31.614c11.3,0,22.601,0.015,33.901-0.024c1.071-0.004,1.46,0.186,1.425,1.361   c-0.093,3.171-0.072,6.347-0.008,9.519c0.021,1.029-0.216,1.367-1.316,1.361c-8.823-0.048-17.647-0.007-26.47-0.052   c-1.135-0.006-1.499,0.229-1.495,1.452c0.049,14.474,0.034,28.947,0.027,43.421c-0.001,1.75,0.22,1.502-1.561,1.51   c-3.173,0.014-6.348-0.038-9.52,0.029c-1.002,0.021-1.285-0.209-1.283-1.258c0.04-14.551,0.029-29.102,0.024-43.653   c-0.001-1.631,0.224-1.472-1.494-1.473c-8.785-0.005-17.57-0.021-26.355,0.022c-1.043,0.005-1.301-0.296-1.281-1.305   c0.063-3.211,0.064-6.425,0-9.635c-0.02-1.004,0.227-1.303,1.272-1.299C97.07,31.629,108.448,31.614,119.826,31.614z"/>
    </g>
    </icon-base>
</template>

<script>
export default {
name: 'htc-brand',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>
